import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { getLeaderboardData, submitScore } from '../utils/mmc-api/api';
import { login } from '../utils/mmc-api/auth';

import CloseIcon from '../assets/icons/close.svg';

const formatGameString = (game) => {
  let result = game.replace(/(?<!\d)([A-Z])/g, ' $1');
  result = result.replace(/([0-9])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export default function LeaderboardController({
  game,
  score,
  time,
  type = 'score',
  onClose = () => {},
}) {
  const [name, setName] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const queryClient = useQueryClient();

  const { data } = useQuery([game], {
    queryFn: async () => {
      return await getLeaderboardData(game);
    },
  });

  const { mutate: submit } = useMutation({
    mutationFn: () => submitScore({ game, score, time, name }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [game] });
      setSubmitted(true);
    },
  });

  const user = useSelector((state) => state.user.data);

  const handleSubmit = () => {
    if (!user.fullname && !name) return;
    submit();
  };

  const handleSignIn = () => {
    console.log(window.location.pathname);
    localStorage.setItem(
      'redirect',
      `${window.location.pathname}?token=${btoa(JSON.stringify({ score, time }))}`,
    );
    login();
  };

  return (
    <div className="modalWrapper">
      <div className="wideModal scrollable">
        <img
          className="modal-close-button"
          src={CloseIcon}
          alt="Close button for modal"
          onClick={onClose}
        />
        <div className="text-center pb-1">
          <h2>{formatGameString(game)} Leaderboard</h2>
        </div>
        {data?.results?.length > 0 ? (
          <table className="table grade-boundary">
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#fd99c5',
                    border: '1px solid black',
                  }}
                >
                  Position
                </th>
                <th
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#fd99c5',
                    border: '1px solid black',
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#fd99c5',
                    border: '1px solid black',
                  }}
                >
                  {type === 'score' ? 'Score' : 'Time'}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((row, i) => (
                <tr key={i}>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {row.fullname || row.username}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {row[type]} {type === 'score' ? 'moves' : 'seconds'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center">This leaderboard is empty. Could you be first?</p>
        )}
        <div className="text-center pb-1">
          {!!score && (
            <p>
              You completed the game in {score} move{score === 1 ? '' : 's'}
            </p>
          )}
          {!!time && (
            <p>
              You completed the game in {time} second{time === 1 ? '' : 's'}
            </p>
          )}
          {(!!score || !!time) &&
            (Object.keys(user ?? {}).length > 0 ? (
              <>
                {!submitted && (
                  <>
                    {!user.fullname && (
                      <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    )}
                    <button className="button" onClick={handleSubmit}>
                      Submit score
                    </button>
                  </>
                )}
              </>
            ) : (
              <>
                <p>You must be signed in to save your high score</p>
                <button className="button" onClick={handleSignIn}>
                  Sign in
                </button>
              </>
            ))}
        </div>
        <div className="modalFooter">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}
