import React from 'react';

function NewGameButton(props) {
  return (
    <button className="NewGameButton" onClick={props.handleNewGameClick}>
      New Game
    </button>
  );
}

export default NewGameButton;
