import React from 'react';
import Board from './Board.jsx';
import ColorPicker from './ColorPicker.jsx';
import NewGameButton from './NewGameButton.jsx';

function Page(props) {
  return (
    <div className="GamePage">
      <div className="flex-h align-flex-end">
        <Board
          colors={props.colors}
          defeat={props.defeat}
          guesses={props.guesses}
          handlePegClick={props.handlePegClick}
          handleScoreClick={props.handleScoreClick}
        />
        <div className="GamePage-controls">
          <ColorPicker
            colors={props.colors}
            selColorIdx={props.selColorIdx}
            handleColorSelection={props.handleColorSelection}
          />
          <NewGameButton handleNewGameClick={props.handleNewGameClick} />
        </div>
      </div>
    </div>
  );
}

export default Page;
