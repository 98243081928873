import React from 'react';
import Confetti from 'react-confetti';

import LeaderboardController from '../Leaderboard';

export default function NumberSlide() {
  const [board, setBoard] = React.useState([]);
  const [moves, setMoves] = React.useState(0);
  const [showLeaderboard, setShowLeaderboard] = React.useState(false);

  React.useEffect(() => {
    const newBoard = generateBoard();
    setBoard(newBoard);
  }, []);

  React.useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) return;
    const decodedToken = JSON.parse(atob(token));
    setMoves(decodedToken.score);
    setShowLeaderboard(true);
    localStorage.removeItem('redirect');
  }, []);

  function generateBoard() {
    let numbers = [];
    for (let i = 1; i <= 15; i++) {
      numbers.push(i);
    }
    numbers.push('x');

    // Shuffle the array
    for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
    }

    // Convert the shuffled array into a 4x4 matrix
    let matrix = [];
    for (let i = 0; i < 4; i++) {
      matrix.push(numbers.slice(i * 4, (i + 1) * 4));
    }

    return matrix;
  }

  const directionOfEmptyCell = (rowNum, colNum) => {
    const emptyCellFlatIndex = board.flat().indexOf('x');
    const emptyCellRow = Math.floor(emptyCellFlatIndex / 4);
    const emptyCellColumn = emptyCellFlatIndex % 4;

    if (emptyCellRow === rowNum) {
      return [emptyCellColumn > colNum ? 'right' : 'left', [emptyCellRow, emptyCellColumn]];
    } else if (emptyCellColumn === colNum) {
      return [emptyCellRow > rowNum ? 'up' : 'down', [emptyCellRow, emptyCellColumn]];
    } else {
      return [false, [emptyCellRow, emptyCellColumn]];
    }
  };

  const moveUp = (clickedCoords, emptyCellCoords) => {
    const col = clickedCoords[1];
    const difference = emptyCellCoords[0] - clickedCoords[0] + 1;
    const boardCopy = structuredClone(board);
    let values = [];
    for (let i = 0; i < difference; i++) {
      values.push(boardCopy[i + clickedCoords[0]][col]);
    }
    const endValue = values.pop();
    values.unshift(endValue);
    for (let i = 0; i < difference; i++) {
      boardCopy[i + clickedCoords[0]][col] = values[i];
    }
    setBoard(boardCopy);
  };

  const moveDown = (clickedCoords, emptyCellCoords) => {
    const col = clickedCoords[1];
    const difference = clickedCoords[0] - emptyCellCoords[0] + 1;
    const boardCopy = structuredClone(board);
    let values = [];
    for (let i = 0; i < difference; i++) {
      values.push(boardCopy[i + emptyCellCoords[0]][col]);
    }
    const endValue = values.shift();
    values.push(endValue);
    for (let i = 0; i < difference; i++) {
      boardCopy[i + +emptyCellCoords[0]][col] = values[i];
    }
    setBoard(boardCopy);
  };

  const moveRight = (clickedCoords, emptyCellCoords) => {
    const row = clickedCoords[0];
    const difference = emptyCellCoords[1] - clickedCoords[1] + 1;
    const boardCopy = structuredClone(board);
    let values = [];
    for (let i = 0; i < difference; i++) {
      values.push(boardCopy[row][i + clickedCoords[1]]);
    }
    const endValue = values.pop();
    values.unshift(endValue);
    for (let i = 0; i < difference; i++) {
      boardCopy[row][i + clickedCoords[1]] = values[i];
    }
    setBoard(boardCopy);
  };

  const moveLeft = (clickedCoords, emptyCellCoords) => {
    const row = clickedCoords[0];
    const difference = clickedCoords[1] - emptyCellCoords[1] + 1;
    const boardCopy = structuredClone(board);
    let values = [];
    for (let i = 0; i < difference; i++) {
      values.push(boardCopy[row][i + emptyCellCoords[1]]);
    }
    const endValue = values.shift();
    values.push(endValue);
    for (let i = 0; i < difference; i++) {
      boardCopy[row][i + emptyCellCoords[1]] = values[i];
    }
    setBoard(boardCopy);
  };

  const moveTile = (rowNum, colNum) => {
    const [relativePositionOfEmptyCell, emptyCellCoords] = directionOfEmptyCell(rowNum, colNum);
    if (!relativePositionOfEmptyCell) return;
    setMoves((moves) => moves + 1);
    switch (relativePositionOfEmptyCell) {
      case 'up':
        moveUp([rowNum, colNum], emptyCellCoords);
        break;
      case 'down':
        moveDown([rowNum, colNum], emptyCellCoords);
        break;
      case 'left':
        moveLeft([rowNum, colNum], emptyCellCoords);
        break;
      case 'right':
        moveRight([rowNum, colNum], emptyCellCoords);
        break;
    }
  };

  const hasWon = board.every((row, rowNum) =>
    row.every((cell, colNum) => {
      if (cell !== 'x') {
        return cell === rowNum * 4 + (colNum + 1);
      } else {
        return rowNum * 4 + (colNum + 1) === 16;
      }
    }),
  );

  React.useEffect(() => {
    if (hasWon && board.length) setShowLeaderboard(true);
  }, [hasWon]);

  return (
    <div className="number-slide-container">
      {hasWon && (
        <div className="confetti-container">
          <Confetti />
        </div>
      )}
      {showLeaderboard && (
        <LeaderboardController
          game="15Puzzle"
          score={hasWon ? moves : 0}
          type="score"
          onClose={() => {
            setShowLeaderboard(false);
          }}
        />
      )}
      <div className="srow x-centered">
        <div className="scolumn narrow">
          <button
            className="button"
            onClick={() => {
              setShowLeaderboard(false);
              setMoves(0);
              setBoard(generateBoard());
            }}
          >
            Reset
          </button>
        </div>
        <div className="scolumn narrow">
          <button className="button" onClick={() => setShowLeaderboard(true)}>
            Leaderboard
          </button>
        </div>
      </div>
      <p className="my-0.5">Moves: {moves}</p>
      <div className="number-slide-grid">
        {board.map((row, rowNum) => (
          <div className="row">
            {row.map((cell, colNum) =>
              cell !== 'x' ? (
                <div className="cell" onClick={() => moveTile(rowNum, colNum)}>
                  {cell}
                </div>
              ) : (
                <div className="empty-cell" />
              ),
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
