import React from 'react';

function ScoreButton(props) {
  return (
    <button className="ScoreButton" disabled={props.disabled} onClick={props.handleScoreClick}>
      ✔
    </button>
  );
}

export default ScoreButton;
