import React from 'react';
import Peg from './Peg.jsx';

function Pegs(props) {
  return (
    <div className="GuessPegs">
      <Peg
        color={props.colors[props.code[0]]}
        currentGuess={props.currentGuess}
        handlePegClick={() => props.handlePegClick(0)}
      />
      <Peg
        color={props.colors[props.code[1]]}
        currentGuess={props.currentGuess}
        handlePegClick={() => props.handlePegClick(1)}
      />
      <Peg
        color={props.colors[props.code[2]]}
        currentGuess={props.currentGuess}
        handlePegClick={() => props.handlePegClick(2)}
      />
      <Peg
        color={props.colors[props.code[3]]}
        currentGuess={props.currentGuess}
        handlePegClick={() => props.handlePegClick(3)}
      />
    </div>
  );
}

export default Pegs;
